<template>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox">
          <div class="ibox-title">
            <h5></h5>
            <div class="ibox-tools">
              <router-link to="addRole">
                <i class="fa fa-plus"></i> &nbsp;&nbsp; Add
              </router-link>
            </div>
          </div>
          <div class="ibox-content">
            <div class="table-responsive">
              <table class="table table-bordered table-hover" :class="dataTablesRole">
                <thead>
                  <tr>
                    <th>{{ roleColumn.id }}</th>
                    <th>{{ roleColumn.name }}</th>
                    <th>{{ roleColumn.description }}</th>
                    <th>{{ roleColumn.menuPrivilege }}</th>
                    <th>{{ roleColumn.apiPrivileges }}</th>
                    <th>{{ roleColumn.action }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="gradeX" v-for="(value, key) in roleData" :key="key">
                    <td style="width: 8%">{{ value.role.id }}</td>
                    <td style="width: 25%">{{ value.role.name }}</td>
                    <td style="width: 28%">{{ value.role.description }}</td>
                    <td class="view" style="width: 10%">
                      <a
                        data-toggle="modal"
                        data-target="#viewMenu"
                        @click="addMenuModaldata(value.menu)"
                        >Menu View <i class="fa fa-eye" aria-hidden="true"></i
                      ></a>
                    </td>
                    <td class="view" style="width: 10%">
                      <a
                        data-toggle="modal"
                        data-target="#viewApi"
                        @click="addApiModaldata(value.api)"
                        >API View <i class="fa fa-eye" aria-hidden="true"></i
                      ></a>
                    </td>
                    <td style="width: 2%">
                      <i class="fas fa-user-cog"></i>
                      <a class="dropdown-toggle" data-toggle="dropdown" href="#">
                        <i class="fa fa-wrench"></i>
                      </a>
                      <ul class="dropdown-menu dropdown-user">
                        <li>
                          <router-link
                            :to="{
                              path: '/updateRole',
                              query: { id: value.role.id },
                            }"
                            class="dropdown-item"
                            >Edit</router-link
                          >
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            data-toggle="modal"
                            data-target="#myModal4"
                            @click="addModalData(value.role)"
                            >Delete</a
                          >
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th>{{ roleColumn.id }}</th>
                    <th>{{ roleColumn.name }}</th>
                    <th>{{ roleColumn.description }}</th>
                    <th>{{ roleColumn.menuPrivilege }}</th>
                    <th>{{ roleColumn.apiPrivileges }}</th>
                    <th>{{ roleColumn.action }}</th>
                  </tr>
                </tfoot>
              </table>
            </div>
            <DeleteModal :data="data"></DeleteModal>
            <MenuViewModal :data="menuData"></MenuViewModal>
            <ApiViewModel :data="apiData"></ApiViewModel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AxiosService from "../plugin/AxiosService.js";
import Constant from "../plugin/Constant.js";
import DataTableService from "../plugin/DataTableService";
import DeleteModal from "../components/common/DeleteModal";
import EventBus from "../plugin/event-bus";
import MenuViewModal from "../components/common/MenuViewModal";
import ApiViewModel from "../components/common/ApiViewModel";
import AuditListner from "../plugin/AuditListner";

export default {
  data() {
    return {
      roleColumn: {
        id: "Id",
        name: "Role Name",
        description: "Description",
        menuPrivilege: "Menu Privileges",
        apiPrivileges: "API Privileges",
        action: "Action",
      },
      roleData: [],
      menuData: {},
      apiData: [],
      dataTablesRole: "dataTables-Role",
      exportTitle: "Role Details",
      data: {
        title: "Role",
        name: "",
        id: 0,
        url: Constant.url.ROLE_DELETE,
      },
      selectedRoleData: {},
    };
  },
  components: {
    DeleteModal,
    MenuViewModal,
    ApiViewModel,
  },
  created: function () {
    this.fetchroleData();
    this.deleteEvent();
  },
  mounted: function () {
  },
  methods: {
    fetchroleData: function () {
      var self = this;
      var responseCode = Constant.data.RESPONSE_CODE;
      AxiosService.get(Constant.url.ROLE_ALL).then(async (result) => {
        self.roleData = result;
        await DataTableService.loadDataTable(self.dataTablesRole, self.exportTitle);
      });
    },
    addModalData: function (value) {
      this.selectedRoleData = value;
      this.data.name = value.name;
      this.data.id = value.id;
    },
    addMenuModaldata: function (value) {
      this.menuData = value;
    },
    addApiModaldata: function (value) {
      this.apiData = value;
    },
    deleteEvent: function () {
      const self = this;
      EventBus.$on(Constant.data.DELETE_EVENT, (data) => {
        if (data.responseCode == Constant.data.SUCCESS) {
          AuditListner.addAuditLog(
            AuditListner.initalizedData(
              Constant.data.ROLE_ACTIVITY,
              Constant.data.DELETE_TITLE,
              AuditListner.roleTemplate(self.selectedRoleData, "", [], [])
            )
          );
          self.fetchroleData();
          DataTableService.reLoadDataTable(self.dataTablesRole);
          DataTableService.loadDataTable(self.dataTablesRole, self.exportTitle);
        }
      });
    },
  },
  beforeDestroy() {
    EventBus.$off(Constant.data.DELETE_EVENT);
  },
};
</script>
<style scoped>
.ibox-tools a {
  cursor: pointer;
  margin-left: 5px;
  color: #0f0f0f !important;
}
a {
  color: #636363;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
.view {
  color: #1c84c6f7;
}
</style>
